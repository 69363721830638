const greys = ["#efefef", "#ddd", "#333", "#111"]

const Colors = {
  gray: greys,
  grey: greys,
  green: ["#7eddc8", "#a4e7d8", "#beeee3", "#d8f4ee", "#f2fbf9"], //0th, 3rd, 5th, 7th and 9th tints via color-hex.com
  red: ["#F2617A", "#F590A1", "#F8B0BC", "#FBCFD7"], //0th, 3rd, 5th, and 7th tints via color-hex.com
  yellow: ["#FFC730", "#FFD76E", "#FFE397", "#FFEEC0"], //0th, 3rd, 5th, and 7th tints via color-hex.com
  black: "#3B3836",
  white: "#fff",
  primary: "#9CDECF",
}

export default Colors