import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { auth, useAuth } from "gatsby-theme-firebase";
import Colors from "../components/colors"

const Header = ({ siteTitle }) => {
  const { isLoading, isLoggedIn, profile } = useAuth();
  // "#7eddc8",
  return (
    <header
      style={{
        background: Colors.green[0]
      }}
    >
      <div
        style={{
          margin: `0 auto`,
          padding: `1.45rem 1.0875rem`,
          display: 'flex',
        }}
      >
        <h1 style={{ margin: 0 }}>
          <Link
            to="/"
            style={{
              color: Colors.white,
              textDecoration: `none`,
            }}
          >
            {siteTitle}
          </Link>
        </h1>
        <div style={{marginLeft:"auto", marginRight:"auto"}} />
        <h2 style={{fontSize:'1.2rem', marginBottom:'0.25rem', marginTop:'auto'}}>
        {
          isLoggedIn ? 
          <a onClick={() => auth.signOut()} style={{color:'white', textDecoration:'none'}}>Sign Out</a> :
          <Link to="/login" style={{color:'white', textDecoration:'none'}}>Login</Link>
        }
        </h2>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
