/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children, resetPadding }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div style={{display: 'flex', flexDirection:'column', justifyContent: 'center'}}>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={
          resetPadding ?
          {
            margin: `0`,
            paddingTop: 0,
          } :
          {
            margin: `0 auto`,
            paddingTop: 0,
            maxWidth: 960,
            padding: '0 2rem'
          }
        }
      >
        <main style={{minHeight:'80vh'}}>{children}</main>
        <footer style={{marginTop: 'auto'}}>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
